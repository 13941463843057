import { useState, useEffect } from 'react';

import dayjs from 'dayjs';
import { dataGridColumns, generateFilter } from '../../utils/helper';
import absenceService from '../../service/absence.service';
import { MainCategoryOption, SubCategoryOption } from '../../config/absence';
import {
  AutoCompleteSelectUI,
  DataGridUI,
  DataGridIdentifiers,
  DatePickerUI,
  FormLabelUI,
  SelectUI,
} from '../Interface';

const AbsenceReports = ({ refetch, values, viewField, operatorsDisabled }) => {
  const { refetchTable, setRefetchTable } = refetch;
  const { setSelectedValues } = values;

  const [reports, setReports] = useState({ rows: [], rowCount: 0 });
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const [query, setQuery] = useState({
    filter: [],
    sorting: {
      sortModel: [{ field: 'startDate', sort: 'desc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
  });

  const [filterFormState, setFilterFormState] = useState({
    startDate: null,
    endDate: null,
    userId: '',
    mainCategory: '',
    subCategory: '',
  });

  const conditions = {
    gte: ['startDate'],
    lte: ['endDate'],
  };

  useEffect(() => {
    const defaultFilter = [
      {
        field: 'archive',
        value: 'N',
      },
    ];

    const newFilter = generateFilter(filterFormState, conditions);
    setQuery((prev) => {
      return { ...prev, filter: [...defaultFilter, ...newFilter] };
    });
  }, [filterFormState]);

  useEffect(() => {
    if (operatorsDisabled) {
      setRowSelectionModel([]);
    }
  }, [operatorsDisabled]);

  useEffect(() => {
    if (Object.keys(query).length > 0) {
      getList();
    }
  }, [query]);

  useEffect(() => {
    if (refetchTable && query.filter?.length > 0) {
      getList();
    }
  }, [refetchTable]);

  const onPaginitonChange = (paginationModel) => {
    setQuery((prev) => {
      return { ...prev, pagination: { paginationModel } };
    });
  };

  const handleSortModelChange = (sortModel) => {
    setQuery((prev) => {
      const paginationModel = {
        pageSize: prev.pagination.paginationModel.pageSize,
        page: 0,
      };

      return {
        ...prev,
        sorting: { sortModel },
        pagination: { paginationModel },
      };
    });
  };

  const getList = () => {
    absenceService.getAbsences(query).then((data) => {
      setReports(data);
      setRefetchTable(false);
    });
  };

  const columns = dataGridColumns([
    {
      field: 'startDate',
      headerName: 'Kezdő dátum',
      flex: 1,
      renderCell: (params) => {
        return dayjs(params.row.startDate).format('YYYY.MM.DD.');
      },
    },
    {
      field: 'endDate',
      headerName: 'Befejező dátum',
      flex: 1,
      renderCell: (params) => {
        return dayjs(params.row.endDate).format('YYYY.MM.DD.');
      },
    },
    {
      field: 'userId',
      headerName: 'Dolgozó',
      flex: 1,
      renderCell: (params) => {
        return params.row.user?.name;
      },
      sortable: false,
    },
    {
      field: 'mainCategory',
      headerName: 'Főkategória',
      flex: 1,
      renderCell: (params) => {
        const mainCategory = MainCategoryOption.find((option) => option.value === params.row.mainCategory);
        return mainCategory ? mainCategory.label : '';
      },
      sortable: false,
    },
    {
      field: 'subCategory',
      headerName: 'Alkategória',
      flex: 1,
      renderCell: (params) => {
        const mainCategoryId = params.row.mainCategory;
        const subCategoryId = params.row.subCategory;
        const subCategory = SubCategoryOption[mainCategoryId]?.find((sub) => sub.value === subCategoryId);
        return subCategory ? subCategory.label : '';
      },
      sortable: false,
    },
  ]);

  return (
    <div className="flex  overflow-hidden m-4 ">
      <div className="h-full w-full  overflow-x-auto ">
        <div className="grid grid-cols-4 gap-x-4 mx-4">
          <div className="col-span-2 md:col-span-1">
            <DatePickerUI
              label={<FormLabelUI text="Kezdő dátum" />}
              value={filterFormState.startDate}
              onChange={(newDate) => {
                setFilterFormState((prevState) => ({
                  ...prevState,
                  startDate: newDate ? newDate.startOf('day') : null,
                }));
              }}
            />
          </div>
          <div className="col-span-2 md:col-span-1">
            <DatePickerUI
              label={<FormLabelUI text="Befejező dátum" />}
              value={filterFormState.endDate}
              onChange={(newDate) => {
                setFilterFormState((prevState) => ({
                  ...prevState,
                  endDate: newDate ? newDate.endOf('day') : null,
                }));
              }}
            />
          </div>
          <div className="col-span-2 md:col-span-1">
            <AutoCompleteSelectUI
              id="worker"
              label={<FormLabelUI text="Dolgozó" />}
              selectedValue={filterFormState.userId}
              table="resourceOne"
              listType={{ id: 'id', name: 'name' }}
              conditions={[`resourceOne.archive = 'N'`]}
              onChange={(_e, newVal, reason) => {
                if (reason === 'clear') {
                  setFilterFormState((prevState) => ({
                    ...prevState,
                    userId: '',
                  }));
                } else {
                  setFilterFormState((prevState) => ({
                    ...prevState,
                    userId: newVal.value,
                  }));
                }
              }}
            />
          </div>
          <div className="col-span-2 md:col-span-1">
            <SelectUI
              label={<FormLabelUI text="Főkategória" />}
              option={MainCategoryOption}
              value={filterFormState.mainCategory}
              onChange={(e) => {
                const value = e.targer.value;
                setFilterFormState((prevState) => ({
                  ...prevState,
                  mainCategory: value,
                }));
              }}
            />
          </div>
          <div className="col-span-2 md:col-span-1">
            <SelectUI
              label={<FormLabelUI text="Alkategória" />}
              value={filterFormState.subCategory}
              option={Object.values(SubCategoryOption).flat()}
              onChange={(e) => {
                const value = e.targer.value;
                setFilterFormState((prevState) => ({
                  ...prevState,
                  subCategory: value,
                }));
              }}
            />
          </div>
        </div>

        <div className="inline-block w-full border-b border-gray-200 align-middle mt-4 overflow-y-auto overflow-x-auto ">
          <DataGridUI
            datagridIdentifier={DataGridIdentifiers.AbsenceReports}
            initialState={query}
            rows={reports.rows}
            rowCount={reports.rowCount}
            columns={columns}
            onRowClick={(rowData) => {
              if (!viewField) return;
              setSelectedValues(rowData.row);
            }}
            paginationMode="server"
            onPaginationModelChange={onPaginitonChange}
            sortingMode="server"
            onSortModelChange={handleSortModelChange}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
            disableRowSelectionOnClick={!viewField}
            height={812}
          />
        </div>
      </div>
    </div>
  );
};

export default AbsenceReports;
