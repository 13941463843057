import { useEffect } from 'react';
import propertyService from '../../service/property.service';
import DynamicPropertyTable from './DynamicPropertyTable';

const DynamicPropertyMain = ({ submenuId, disabled, selectedValues, canEdit, setDefaultValues, dynProperties }) => {
  const { dynProps, setDynProps } = dynProperties;

  useEffect(() => {
    if (submenuId > 0) {
      getFormProperties();
    }
  }, [submenuId]);

  const getFormProperties = () => {
    propertyService.getPropertiesByFormId(submenuId).then((data) => {
      if (!canEdit) {
        const teszt = data.filter(
          (filtered) => filtered.property.name !== 'Szerződött alap óradíj' && filtered.property.id !== 16
        );
        setDynProps(teszt);
      } else {
        setDynProps(data);
      }
    });
  };

  return (
    <DynamicPropertyTable
      rows={dynProps}
      disabled={disabled}
      selectedValues={selectedValues}
      setDefaultValues={setDefaultValues}
    />
  );
};

export default DynamicPropertyMain;
