import { useState } from 'react';
import RoleList from './RoleList';
import { TabsUI } from '../Interface';
import RoleInformation from './RoleInformation';
import BiRoleInformation from './BiRoleInformation';

export default function RoleIndex() {
  const [activeTab, setActiveTab] = useState(0);
  const [roleId, setRoleId] = useState(null);

  const tabs = [
    {
      id: 1,
      name: 'Menüpontok',
      content: <RoleInformation roleState={{ roleId, setRoleId }} />,
    },
    {
      id: 2,
      name: 'Kimutatások',
      content: <BiRoleInformation roleState={{ roleId, setRoleId }} />,
    },
  ];

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <main className="px-2 pt-1 bg-gray-100 min-h-screen w-full fixed">
      <div className="grid grid-cols-4 mt-5">
        <RoleList roleState={{ roleId, setRoleId }} />
        <div className="col-span-3">
          <TabsUI activeTab={activeTab} handleTabClick={handleTabClick}>
            {tabs.map((tab, index) => (
              <div key={index} name={tab.name}>
                {tab.content}
              </div>
            ))}
          </TabsUI>
        </div>
      </div>
    </main>
  );
}
