import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import appService from '../../service/app.service';

const AutoCompleteSelectUI = (props) => {
  const {
    id,
    label,
    table,
    listType,
    selectedValue,
    selectedLabelValue,
    conditions,
    joins,
    multiple,
    selectedColumns,
    dataset,
  } = props;

  const [list, setList] = useState([]);
  const [defaultList, setDefaultList] = useState([]);
  const [value, setValue] = useState(multiple ? [] : null);

  useEffect(() => {
    fetchData();
  }, [table, conditions]);

  useEffect(() => {
    setList(defaultList);
  }, [defaultList]);

  useEffect(() => {
    if (dataset) setDefaultList(dataset);
  }, [dataset]);

  useEffect(() => {
    const findedListItem = list.find((p) => p.value === selectedValue);

    if (!findedListItem && selectedValue > 0) {
      const newVal = { value: selectedValue, label: selectedLabelValue ?? '' };
      setList([...defaultList, newVal]);
      setValue(newVal);
    } else {
      if (multiple) {
        setList(defaultList);
        setValue(selectedValue);
      } else {
        setList(defaultList);
        setValue(findedListItem || null);
      }
    }
  }, [selectedValue]);

  const fetchData = () => {
    if (table) {
      const requestData = {
        tables: Array.isArray(table) ? table : [table],
        joins: Array.isArray(joins) ? joins : joins ? [joins] : [],
        conditions: Array.isArray(conditions) ? conditions : conditions ? [conditions] : [],
        selectedColumns: Array.isArray(selectedColumns) ? selectedColumns : selectedColumns ? [selectedColumns] : ['*'],
      };

      appService.getJoinedData(requestData).then((data) => {
        const formattedData = data.map((item) => ({
          label: item[listType.name],
          value: item[listType.id],
        }));
        setDefaultList(formattedData);
      });
    } else {
      setDefaultList([]);
    }
  };

  return (
    <div className="z-50 w-full">
      <Autocomplete
        clearOnEscape
        id={id}
        name={id}
        value={value}
        options={list}
        onChange={(_event, newValue) => setValue(newValue)}
        sx={{ width: '100%' }}
        renderInput={(params) => (
          <TextField {...params} variant="standard" label={label} helperText={props.helperText} />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props} key={uuidv4()}>
              {option.label}
            </li>
          );
        }}
        {...props}
      />
    </div>
  );
};
export default AutoCompleteSelectUI;
