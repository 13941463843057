import React, { useEffect, useState } from 'react';

import { dataGridColumns } from '../../utils/helper';
import propertyService from '../../service/property.service';
import { NotificationType } from '../../config';
import addNotification from '../../utils/addNotification';

import { DataGridUI, DataGridIdentifiers } from '../Interface';

const FormsDataGrid = ({ formProperty, id, setRefetchTable, disabled }) => {
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    const selectedIds = formProperty.filter((row) => row.checked).map((row) => row.subMenuId);
    setSelectedRows(selectedIds);
    setRefetchTable(true);
  }, [formProperty]);

  const updateFormProperty = (formId) => {
    propertyService.updateFormProperty(id, formId);
    if (formId !== null) {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });
      setRefetchTable(true);
    }
  };

  const handleRowSelectionModelChange = (newRowSelectionModel) => {
    const changedRows = [
      ...newRowSelectionModel.filter((row) => !selectedRows.includes(row)),
      ...selectedRows.filter((row) => !newRowSelectionModel.includes(row)),
    ];

    changedRows.forEach((formId) => {
      updateFormProperty(formId);
    });

    setSelectedRows(newRowSelectionModel);
  };

  const columns = dataGridColumns([
    {
      field: 'subMenuName',
      headerName: 'Formok',
      flex: 1,
    },
  ]);

  return (
    <DataGridUI
      datagridIdentifier={DataGridIdentifiers.FormsDataGrid}
      sx={{
        width: '100%',
        height: 560,
        '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
          display: 'none',
        },
        '& .MuiDataGrid-columnHeaderDraggableContainer': {
          backgroundColor: '#CEDFE8',
        },
      }}
      columns={columns}
      rows={formProperty}
      getRowId={(row) => row.subMenuId}
      checkboxSelection
      disableRowSelectionOnClick={true}
      rowSelectionModel={selectedRows}
      isRowSelectable={() => !disabled}
      onRowSelectionModelChange={handleRowSelectionModelChange}
    />
  );
};

export default FormsDataGrid;
