import { useState, useEffect } from 'react';

import dayjs from 'dayjs';

import projectService from '../../service/project.service';
import { dataGridColumns } from '../../utils/helper';
import { generateFilter } from '../../utils/helper';
import { ActiveTypeOption } from '../../config/activeTypes';

import {
  DatePickerUI,
  AutoCompleteSelectUI,
  DataGridUI,
  DataGridIdentifiers,
  DebouncedInputUI,
  FormLabelUI,
  OrderArrowsUI,
  SelectUI,
} from '../Interface';

export default function ProjectReports({ projectState }) {
  const {
    setRefetchTable,
    setSelectedValues,
    viewField,
    operatorsDisabled,
    refetchTable,
    submenu,
    rowSelectionModel,
    setRowSelectionModel,
  } = projectState;

  const [reports, setReports] = useState({ rows: [], rowCount: 0 });

  const [query, setQuery] = useState({
    filter: [],
    sorting: {
      sortModel: [{ field: 'visOrder', sort: 'asc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
  });

  const [filterFormState, setFilterFormState] = useState({
    projectStartDate: null,
    projectEndDate: null,
    userId: '',
    statusId: [],
    companyId: '',
    projectName: '',
    projectGroupId: '',
    orderNumber: '',
    active: '',
  });

  const conditions = {
    like: ['projectName'],
    gte: ['projectStartDate'],
    lte: ['projectEndDate'],
  };

  useEffect(() => {
    const defaultFilter = [
      {
        field: 'archive',
        value: 'N',
      },
    ];
    const newStatus = filterFormState.statusId.map((sIds) => sIds.value);
    const newFilterVal = { ...filterFormState, statusId: newStatus };
    const newFilter = generateFilter(newFilterVal, conditions);
    setQuery((prev) => {
      return { ...prev, filter: [...defaultFilter, ...newFilter] };
    });
  }, [filterFormState]);

  useEffect(() => {
    if (operatorsDisabled) {
      setRowSelectionModel([]);
    }
  }, [operatorsDisabled]);

  useEffect(() => {
    if (Object.keys(query).length > 0) {
      getList();
    }
  }, [query]);

  useEffect(() => {
    if (refetchTable && query.filter?.length > 0) {
      getList();
    }
  }, [refetchTable]);

  const onPaginitonChange = (paginationModel) => {
    setQuery((prev) => {
      return { ...prev, pagination: { paginationModel } };
    });
  };

  const handleSortModelChange = (sortModel) => {
    setQuery((prev) => {
      const paginationModel = {
        pageSize: prev.pagination.paginationModel.pageSize,
        page: 0,
      };

      return {
        ...prev,
        sorting: { sortModel },
        pagination: { paginationModel },
      };
    });
  };

  const orderProject = (projectId, newOrder) => {
    projectService.orderProject(projectId, { newOrder }).then(() => {
      setRefetchTable(true);
    });
  };

  const handleOrderChange = (projectId, direction) => {
    orderProject(projectId, direction);
  };

  const getList = () => {
    projectService.getProjects(query).then((data) => {
      setReports(data);
      setRefetchTable(false);
    });
  };

  const showActive = (params) => {
    const { active } = params.row;
    if (active === true) {
      return 'Igen';
    } else if (active === false) {
      return 'Nem';
    }
  };

  const columns = dataGridColumns([
    {
      field: 'projectName',
      headerName: 'Projekt neve',
      flex: 0.5,
    },
    {
      field: 'companyName',
      headerName: 'Cég',
      flex: 0,
      sortable: false,
      valueGetter: (data) => {
        return data.row.company?.companyName;
      },
    },
    {
      field: 'statusName',
      headerName: 'Státusz',
      flex: 0,
      sortable: false,
      valueGetter: (data) => {
        return data.row.status?.itemName;
      },
    },
    {
      field: 'projectStartDate',
      headerName: 'Kezdő időpont',
      flex: 0.3,
      valueGetter: (data) => {
        return dayjs(data.row.projectStartDate).format('YYYY.MM.DD.');
      },
    },
    {
      field: 'projectEndDate',
      headerName: 'Befejező időpont',
      flex: 0.3,
      valueGetter: (data) => {
        return dayjs(data.row.projectEndDate).format('YYYY.MM.DD.');
      },
    },
    {
      field: 'groupitemName',
      headerName: 'Projekt csoport',
      flex: 0.3,
      sortable: false,
      valueGetter: (data) => {
        return data.row.projectGroup?.itemName;
      },
    },
    {
      field: 'projectPrepare',
      headerName: '%',
      flex: 0,
      sortable: false,
      valueGetter: (data) => {
        const preparedness = data.row.projectPrepare;
        return preparedness > 0 ? `${preparedness}%` : '';
      },
    },
    {
      field: 'userName',
      headerName: 'Tulajdonos',
      flex: 0,
      sortable: false,
      valueGetter: (data) => {
        return data.row.user?.userName;
      },
    },
    {
      field: 'visOrder',
      headerName: 'Sorrend',
      flex: 0,
      renderCell: (params) => {
        const disabled = params?.api?.getSortModel()[0]?.field !== 'visOrder';
        const isSortedDescending = params.api.getSortModel()[0]?.sort === 'desc';

        const handleUpClick = () => {
          if (isSortedDescending) {
            handleOrderChange(params.row.projectId, 'down');
          } else {
            handleOrderChange(params.row.projectId, 'up');
          }
        };

        const handleDownClick = () => {
          if (isSortedDescending) {
            handleOrderChange(params.row.projectId, 'up');
          } else {
            handleOrderChange(params.row.projectId, 'down');
          }
        };

        return <OrderArrowsUI onUpClick={handleUpClick} onDownClick={handleDownClick} disabled={disabled} />;
      },
    },
    {
      field: 'orderNumber',
      headerName: 'Rendelési szám',
      flex: 0,
    },
    {
      field: 'active',
      headerName: 'Aktív',
      flex: 0,
      renderCell: (params) => {
        return showActive(params);
      },
    },
  ]);

  return (
    <div className="flex  overflow-hidden m-4 ">
      <div className="h-full w-full  overflow-x-auto ">
        <div className="grid grid-cols-4 gap-x-4 mx-4">
          <div className="col-span-2 md:col-span-1">
            <DatePickerUI
              label={<FormLabelUI text="Kezdő dátum" />}
              value={filterFormState.projectStartDate}
              onChange={(newDate) => {
                setFilterFormState((prevState) => ({
                  ...prevState,
                  projectStartDate: newDate ? newDate.startOf('day') : null,
                }));
              }}
            />
          </div>
          <div className="col-span-2 md:col-span-1">
            <DatePickerUI
              label={<FormLabelUI text="Befejező dátum" />}
              value={filterFormState.projectEndDate}
              onChange={(newDate) => {
                setFilterFormState((prevState) => ({
                  ...prevState,
                  projectEndDate: newDate ? newDate.endOf('day') : null,
                }));
              }}
            />
          </div>
          {/*
          <div className="col-span-2 md:col-span-1">
            <AutoCompleteSelectUI
              id="worker"
              label={<FormLabelUI text="Tulajdonos" />}
              selectedValue={filterFormState.userId}
              table="WebUser"
              listType={{ id: 'userId', name: 'userName' }}
              onChange={(_e, newVal, reason) => {
                if (reason === 'clear') {
                  setFilterFormState((prevState) => ({
                    ...prevState,
                    userId: '',
                  }));
                } else {
                  setFilterFormState((prevState) => ({
                    ...prevState,
                    userId: newVal.value,
                  }));
                }
              }}
            />
          </div>
       */}
          <div className="col-span-2 md:col-span-2">
            <AutoCompleteSelectUI
              label={<FormLabelUI text="Státusz" />}
              onChange={(_event, newVal, _reason) => {
                setFilterFormState((prev) => ({
                  ...prev,
                  statusId: newVal,
                }));
              }}
              selectedValue={filterFormState.statusId}
              disabled={false}
              id={'selectedStatus'}
              table={['statusitems', 'status']}
              listType={{ id: 'itemId', name: 'itemName' }}
              joins={'statusitems.statusId = status.statusId'}
              conditions={[`status.subMenuId = ${submenu}`, `statusitems.archive='N'`]}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              multiple
              limitTags={2}
            />
          </div>
          <div className="col-span-2 md:col-span-1">
            <AutoCompleteSelectUI
              id="companyId"
              label={<FormLabelUI text="Cég" />}
              table="company"
              selectedValue={filterFormState.companyId}
              listType={{ id: 'companyId', name: 'companyName' }}
              onChange={(_e, newVal, reason) => {
                if (reason === 'clear') {
                  setFilterFormState((prevState) => ({
                    ...prevState,
                    companyId: '',
                  }));
                } else {
                  setFilterFormState((prevState) => ({
                    ...prevState,
                    companyId: newVal.value,
                  }));
                }
              }}
            />
          </div>
          <div className="col-span-2 md:col-span-1">
            <DebouncedInputUI
              label={<FormLabelUI text="Projekt neve" />}
              debounceMs={800}
              setQuickFilterSearchValue={(newValue) => {
                setFilterFormState((prevState) => ({
                  ...prevState,
                  projectName: newValue,
                }));
              }}
              quickFilterSearchValue={filterFormState.projectName}
            />
          </div>
          {/*
          <div className="col-span-2 md:col-span-1">
            <AutoCompleteSelectUI
              id="shift"
              selectedValue={filterFormState.projectGroupId}
              label={<FormLabelUI text="Projekt csoport" />}
              table={['ProjectGroupListitems', 'ProjectGroup']}
              joins={'ProjectGroupListitems.projectGroupStatusId = ProjectGroup.projectGroupStatusId'}
              listType={{ id: 'itemId', name: 'itemName' }}
              conditions={`ProjectGroup.subMenuId = ${submenu}`}
              onChange={(_e, newVal, reason) => {
                if (reason === 'clear') {
                  setFilterFormState((prevState) => ({
                    ...prevState,
                    projectGroupId: '',
                  }));
                } else {
                  setFilterFormState((prevState) => ({
                    ...prevState,
                    projectGroupId: newVal.value,
                  }));
                }
              }}
            />
          </div>
*/}
          <div className="col-span-2 md:col-span-1">
            <DebouncedInputUI
              label={<FormLabelUI text="Rendelési szám" />}
              debounceMs={800}
              setQuickFilterSearchValue={(newValue) => {
                setFilterFormState((prevState) => ({
                  ...prevState,
                  orderNumber: newValue,
                }));
              }}
              quickFilterSearchValue={filterFormState.orderNumber}
            />
          </div>
          <div className="col-span-2 md:col-span-1">
            <SelectUI
              label={<span className="text-labelColor">Aktív</span>}
              value={filterFormState.active}
              option={ActiveTypeOption}
              onChange={(e) => {
                const value = e.target.value;
                setFilterFormState((prev) => ({
                  ...prev,
                  active: value,
                }));
              }}
            />
          </div>
        </div>
        <div className="inline-block w-full border-b border-gray-200 align-middle mt-4 overflow-y-auto overflow-x-auto ">
          <DataGridUI
            datagridIdentifier={DataGridIdentifiers.Projects}
            initialState={query}
            rows={reports.rows}
            rowCount={reports.rowCount}
            columns={columns}
            onRowClick={(rowData) => {
              if (!viewField) return;
              setSelectedValues(rowData.row);
            }}
            paginationMode="server"
            onPaginationModelChange={onPaginitonChange}
            sortingMode="server"
            onSortModelChange={handleSortModelChange}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
            disableRowSelectionOnClick={!viewField}
            getRowId={(row) => row.projectId}
            height={700}
          />
        </div>
      </div>
    </div>
  );
}
