import React, { useEffect } from 'react';
import { Field } from 'formik';
import { useNavigate } from 'react-router-dom';

import { InputUI, FormErrorUI, FormLabelUI, AutoCompleteSelectUI, ButtonUI, SelectUI } from '../../Interface';
import { BiAnalysisTypeList, BiReportTypeList, BiReportType, BiOrderTypeList } from '../../../config/bi_report';

const BiReportForm = ({
  values,
  validateField,
  setFieldValue,
  errors,
  autocompleteData,
  getFields,
  reportId,
  defaultField,
}) => {
  const navigate = useNavigate();

  const reportFieldHandler = (newType) => {
    const newFields = [];
    newFields.push(defaultField);

    // later, types that cannot be grouped can be expanded here
    const grouppableTypes = Object.keys(BiReportType).filter((key) => key !== BiReportType.TABLE);

    const isValidType = grouppableTypes.includes(newType);

    if (isValidType) newFields.push({ ...defaultField, isGroup: true });

    setFieldValue('biField', newFields);
  };

  return (
    <>
      <div className="grid grid-cols-3 gap-x-4 mx-4 mb-5 pt-2">
        <div className="col-span-1 ">
          <ButtonUI
            text={'Vissza'}
            className="bg-primary"
            type="button"
            onClick={() => {
              navigate(`/app/bi/query`);
            }}
          ></ButtonUI>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-x-4 mx-4 mb-5">
        <div className="col-span-1 ">
          <Field
            type="autocomplete"
            name="biHeadId"
            component={AutoCompleteSelectUI}
            fullWidth
            label={<FormLabelUI text="Forrás" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.biHeadId} />}
            onChange={(_e, newVal) => {
              setFieldValue('biHeadId', newVal?.value ?? null).then(() => {
                validateField('biHeadId');
                getFields(newVal?.value ?? null);
                if (newVal?.value !== values.biHeadId) {
                  //@TODO confirm
                  setFieldValue('biField', []);
                  setFieldValue('reportType', null);
                  setFieldValue('analysis', null);
                }
              });
            }}
            InputLabelProps={{ shrink: values.element !== '' }}
            selectedValue={values.biHeadId}
            dataset={autocompleteData.biHeads}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            disabled={reportId}
          />
        </div>

        <div className="col-span-1 ">
          <Field
            type="text"
            name="name"
            as={InputUI}
            fullWidth
            label={<FormLabelUI text="Alias név" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.name} />}
            InputLabelProps={{ shrink: values.name !== '' }}
          />
        </div>

        <div className="col-span-1 ">
          <Field
            type="select"
            name="reportType"
            component={SelectUI}
            fullWidth
            option={BiReportTypeList}
            value={values.reportType ?? ''}
            variant="standard"
            onChange={(e) => {
              setFieldValue('reportType', e.target.value ?? null).then(() => {
                validateField('reportType');
                if (e.target.value === BiReportType.TABLE) {
                  setFieldValue('analysis', null);
                }
                reportFieldHandler(e.target.value ?? null);
              });
            }}
            label={<FormLabelUI text="Típus" />}
            helperText={<FormErrorUI message={errors.reportType} />}
            InputLabelProps={{ shrink: values.element !== '' }}
          />
        </div>
        {values?.reportType !== BiReportType.TABLE && (
          <>
            <div className="col-span-1 ">
              <Field
                name={`analysis`}
                component={SelectUI}
                fullWidth
                option={BiAnalysisTypeList}
                value={values.analysis ?? ''}
                variant="standard"
                onChange={(e) => {
                  setFieldValue(`analysis`, e.target.value ?? null).then(() => {
                    validateField(`analysis`);
                  });
                }}
                label={<FormLabelUI text="Analízis" />}
                helperText={<FormErrorUI message={errors.analysis} />}
                InputLabelProps={{ shrink: values.analysis }}
              />
            </div>
            <div className="col-span-1 grid grid-cols-5 gap-x-2">
              <div className="col-span-3 ">
                <Field
                  type="autocomplete"
                  name={`orderField`}
                  component={AutoCompleteSelectUI}
                  fullWidth
                  label={<FormLabelUI text="Rendezés" />}
                  variant="standard"
                  helperText={<FormErrorUI message={errors.orderField} />}
                  inputLabelProps={{ shrink: values.orderField !== null }}
                  onChange={(_e, newVal) => {
                    setFieldValue(`orderField`, newVal?.value ?? null).then(() => {
                      validateField(`orderField`);
                    });
                  }}
                  //@TODO ezt ki kell vizsgálni!!!
                  value={values.orderField ?? null}
                  dataset={autocompleteData.biField}
                  isOptionEqualToValue={(option, value) => option.value === value}
                />
              </div>
              <div className="col-span-2 ">
                <Field
                  name={`orderType`}
                  component={SelectUI}
                  fullWidth
                  option={BiOrderTypeList}
                  value={values.orderType ?? ''}
                  variant="standard"
                  onChange={(e) => {
                    setFieldValue(`orderType`, e.target.value ?? null).then(() => {
                      validateField(`orderType`);
                    });
                  }}
                  label={<FormLabelUI text="Rend. típ." />}
                  helperText={<FormErrorUI message={errors.orderType} />}
                  InputLabelProps={{ shrink: values.orderType !== '' }}
                />
              </div>
            </div>
            <div className="col-span-1 grid grid-cols-5 gap-x-2">
              <div className="col-span-3 ">
                <Field
                  type="number"
                  name="limit"
                  precision={1}
                  as={InputUI}
                  fullWidth
                  label={<FormLabelUI text="Limit" />}
                  variant="standard"
                  helperText={<FormErrorUI message={errors.limit} />}
                  InputLabelProps={{ shrink: values.limit !== '' && values.limit !== null }}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default BiReportForm;
