import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';

import DynamicPropertyField from './DynamicPropertyField';
import { getDynVals } from '../../utils/DynHelper';

const DynamicPropertyTable = ({ rows, disabled, selectedValues, setDefaultValues }) => {
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    if (rows.length > 0) {
      const dynObj = getDynVals(rows);

      if (Object.keys(dynObj).length > 0) {
        setDefaultValues((prev) => ({ ...prev, dynamicFields: dynObj }));
        setFieldValue('dynamicFields', dynObj);
      }
    }
  }, [rows, setFieldValue, setDefaultValues]);

  return (
    <div className="max-h-[400px] overflow-y-scroll overflow-x-hidden custom-scrollbar">
      <table className="border w-full">
        <thead className="bg-tableHeaderColor">
          <tr className="grid grid-cols-4">
            <th className="border p-3 col-span-2">Megnevezés</th>
            <th className="border p-3 col-span-2">Érték</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={row.property.id} className={index % 2 === 0 ? 'bg-gray-100 grid grid-cols-4' : 'grid grid-cols-4'}>
              <td className="border py-1 col-span-2">{row.property.name}</td>
              <td className="border py-1 col-span-2 relative">
                <div className="relative w-full">
                  <DynamicPropertyField
                    fieldData={row}
                    name={`dynamicFields.din_${row.property.id}`}
                    disabled={disabled}
                    selectedValues={selectedValues}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DynamicPropertyTable;
