import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  DataGridPremium,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
  GridToolbar,
  getGridDateOperators,
} from '@mui/x-data-grid-premium';
import { LicenseInfo } from '@mui/x-license';
import dayjs from 'dayjs';
import {
  Select,
  MenuItem,
  IconButton,
  Modal,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';
import { Delete, Save } from '@mui/icons-material';
import { ButtonUI } from '../../Interface';
import biService from '../../../service/bi.service';
import { BiFieldType } from '../../../config/bi_report';
import { huHU } from '@mui/x-data-grid/locales';
import { getDataGridStyles } from '../../Interface/Datagrid/dataGridStyles';

LicenseInfo.setLicenseKey('');

const TableReport = ({ report }) => {
  const apiRef = useGridApiRef();
  const [gridData, setGridData] = useState({ rows: [], rowCount: 0 });
  const [columns, setColumns] = useState([]);
  const [savedStates, setSavedStates] = useState({});
  const [selectedState, setSelectedState] = useState('');
  const [isSelectOpen, setSelectOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [stateName, setStateName] = useState('');
  const [isOverwriteDialogOpen, setOverwriteDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [stateToDelete, setStateToDelete] = useState('');

  const query = useMemo(
    () => ({
      filter: [],
      sorting: {
        sortModel: [],
      },
      pagination: {
        paginationModel: {
          page: 0,
        },
      },
    }),
    [report.id]
  );

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: [],
        aggregation: {
          model: {},
        },
      },
    },
  });

  const getTableData = useCallback(() => {
    biService.getReportData(report.id, query).then((data) => setGridData(data));
  }, [report.id, query]);

  const generateColumns = () => {
    const col = report.biField.map((field) => {
      const column = {
        field: field?.field,
        headerName: field?.name,
        headerClassName: 'bg-tableHeaderColor',
        filterable: true,
        sortable: true,
        flex: 1,
      };

      switch (field.type) {
        case BiFieldType.DATE:
          column.type = 'date';
          column.valueGetter = (value) => new Date(value);
          column.renderCell = (value) => {
            return dayjs(value.row?.[field?.field]).format('YYYY.MM.DD');
          };

          break;
        case BiFieldType.DATETIME:
          column.type = 'dateTime';
          column.valueGetter = (value) => new Date(value);
          column.renderCell = (value) => {
            return dayjs(value.row?.[field?.field]).format('YYYY.MM.DD HH:mm');
          };
          break;
        case BiFieldType.TIME:
          column.valueFormatter = (data) => (data ? dayjs(data).format('HH:mm') : '');
          break;
        case BiFieldType.NUMBER:
          column.type = 'number';
          column.valueGetter = (data) => {
            return data ? parseFloat(data) : '';
          };
          break;
        default:
          break;
      }

      return column;
    });

    setColumns(col);
  };

  useEffect(() => {
    if (Object.keys(report).length) {
      generateColumns();
    }
  }, [report, report?.biField]);

  useEffect(() => {
    if (Object.keys(query).length > 0) {
      getTableData();
    }
  }, [query]);

  useEffect(() => {
    biService.getReportReportGridState(report.id).then((savedStateTable) => setSavedStates(savedStateTable));
    // const savedStates = JSON.parse(localStorage.getItem(`biGridStates_${report.id}`)) || {};
    // setSavedStates(savedStates);
  }, [report.id]);

  const handleSaveState = () => {
    setModalOpen(true);
  };

  const handleSaveStateConfirm = () => {
    if (savedStates[stateName]) {
      setModalOpen(false);
      setOverwriteDialogOpen(true);
    } else {
      saveState();
    }
  };

  const saveState = async () => {
    const currentState = apiRef.current.exportState();
    const newSavedStates = { ...savedStates, [stateName]: currentState };
    setSavedStates(newSavedStates);
    // localStorage.setItem(`biGridStates_${report.id}`, JSON.stringify(newSavedStates));
    await biService.saveReportGridState(report.id, newSavedStates);
    setModalOpen(false);
    setOverwriteDialogOpen(false);
    setStateName('');
  };

  const handleConfirmOverwrite = () => {
    saveState();
  };

  const handleLoadState = useCallback(
    (stateName) => {
      const savedState = savedStates[stateName];
      if (savedState) {
        apiRef.current.restoreState(savedState);
      }
    },
    [savedStates, apiRef]
  );

  const handleDeleteState = (stateName) => {
    setStateToDelete(stateName);
    setDeleteDialogOpen(true);
  };

  const confirmDeleteState = async () => {
    const newSavedStates = { ...savedStates };
    delete newSavedStates[stateToDelete];
    setSavedStates(newSavedStates);
    // localStorage.setItem(`biGridStates_${report.id}`, JSON.stringify(newSavedStates));
    await biService.saveReportGridState(report.id, newSavedStates);
    setDeleteDialogOpen(false);
    setStateToDelete('');
    if (selectedState === stateToDelete) {
      setSelectedState('');
    }
  };

  useEffect(() => {
    if (selectedState) {
      handleLoadState(selectedState);
    }
  }, [selectedState, handleLoadState]);

  return (
    <div className="grid grid-cols-1 gap-2 p-4">
      <div className="col-span-1 flex items-center justify-center text-labelColor text-xl">{report.name}</div>
      <div className="flex items-center space-x-2 mb-4">
        <Select
          value={selectedState}
          onChange={(e) => setSelectedState(e.target.value)}
          onOpen={() => setSelectOpen(true)}
          onClose={() => setSelectOpen(false)}
          displayEmpty
        >
          <MenuItem value="" disabled>
            Mentett állapot kiválasztása
          </MenuItem>
          {Object.keys(savedStates)
            .sort()
            .map((state) => (
              <MenuItem key={state} value={state}>
                {state}
                {isSelectOpen && (
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteState(state);
                    }}
                    style={{ marginLeft: 'auto' }}
                  >
                    <Delete fontSize="small" />
                  </IconButton>
                )}
              </MenuItem>
            ))}
        </Select>
        <IconButton aria-label="save" size="medium" onClick={handleSaveState}>
          <Save />
        </IconButton>
      </div>
      <div>
        <DataGridPremium
          initialState={initialState}
          apiRef={apiRef}
          rows={gridData.rows}
          rowCount={gridData.rows.length}
          columns={columns}
          groupingColDef={{
            hideable: false,
          }}
          pagination
          rowHeight={45}
          sx={getDataGridStyles({ height: 550 })}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            columnsPanel: {
              sx: {
                '& .MuiDataGrid-panelFooter button:first-child': {
                  display: 'none',
                },
              },
            },
          }}
          localeText={huHU.components.MuiDataGrid.defaultProps.localeText}
          disableSelectionOnClick
          headerFilters
        />
      </div>

      {/* Save State Modal */}
      <Modal open={isModalOpen} onClose={() => setModalOpen(false)}>
        <div
          className="modal-container"
          style={{
            padding: 20,
            backgroundColor: 'white',
            margin: 'auto',
            top: '20%',
            position: 'relative',
            width: 300,
          }}
        >
          <h2>Jelenlegi állapot mentése</h2>
          <TextField label="State Name" value={stateName} onChange={(e) => setStateName(e.target.value)} fullWidth />
          <Button variant="contained" onClick={handleSaveStateConfirm} disabled={!stateName} style={{ marginTop: 10 }}>
            Mentés
          </Button>
        </div>
      </Modal>

      {/* Overwrite Confirmation Dialog */}
      <Dialog open={isOverwriteDialogOpen} onClose={() => setOverwriteDialogOpen(false)}>
        <DialogTitle>Felülírás megerősítése</DialogTitle>
        <DialogContent>
          <DialogContentText>Már létezik egy állapot "{stateName}" névvel. Szeretné felülírni?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOverwriteDialogOpen(false)}>Mégsem</Button>
          <Button onClick={handleConfirmOverwrite} color="primary" variant="contained">
            Felülír
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={isDeleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Törlés megerősítése</DialogTitle>
        <DialogContent>
          <DialogContentText>Biztos benne, hogy törölni szeretné a(z) "{stateToDelete}" állapotot?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <ButtonUI text={'Törlés'} className="bg-danger" type="button" onClick={confirmDeleteState} />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TableReport;
