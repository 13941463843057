import { useEffect, useMemo, useState } from 'react';
import useMenus from '../context/MenuContext';
import useParam from '../context/ParamContext';
import { useLocation } from 'react-router-dom';

export const useProject = () => {
  const location = useLocation();
  const { menus, getMenus, getMenuSubMenuId, getSubMenuNamesByUrls, getCanEdit } = useMenus();
  const { getParam, params } = useParam();
  const [refetchTable, setRefetchTable] = useState(true);
  const [viewField, setViewField] = useState(true);
  const [selectedValues, setSelectedValues] = useState({});
  const [operatorsDisabled, setOperatorsDisabled] = useState({});
  const [submenu, setSubmenu] = useState(null);
  const [canEdit, setCanEdit] = useState(true);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  useEffect(() => {
    getMenus();
    getParam();
  }, []);

  useEffect(() => {
    const submenuId = location.pathname.includes('projectInfoPanel')
      ? getMenuSubMenuId('projectInfoPanel')
      : getMenuSubMenuId('Project');

    if (location.pathname.includes('projectInfoPanel')) {
      const submenu = getMenuSubMenuId('Project');
      setSubmenu(submenu);
    } else {
      setSubmenu(submenuId);
    }

    setCanEdit(getCanEdit(submenuId));
  }, [menus, location]);

  const submenuNames = useMemo(() => {
    return getSubMenuNamesByUrls(['resourceOne', 'resourceTwo', 'resourceThree', 'resourceFour', 'machine']);
  }, [menus]);

  return {
    refetchTable: refetchTable || false,
    setRefetchTable: setRefetchTable || (() => {}),
    viewField,
    setViewField,
    selectedValues,
    setSelectedValues,
    operatorsDisabled,
    setOperatorsDisabled,
    submenu,
    submenuNames,
    params,
    canEdit,
    rowSelectionModel,
    setRowSelectionModel,
  };
};
