import { ButtonUI, DialogUI } from '../Interface';

export const DynamicColDialog = ({
  open,
  handleClose,
  saveView,
  resourceColumnDynamicColsObj,
  dynamicColsVisibility,
  handleChangeDynamicCol,
}) => {
  return (
    <DialogUI
      open={open}
      onClose={handleClose}
      headerContent="Oszlopok kezelése"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '800px',
          },
        },
      }}
    >
      <div className="mt-5">
        <div className="text-labelColor">
          <table className="border w-full">
            <thead className="bg-tableHeaderColor">
              <tr className="grid grid-cols-4">
                <th className="border p-3 col-span-1">Oszlop neve</th>
                <th className="border p-3 col-span-2">Dinamikus tulajdonság neve</th>
                <th className="border p-3 col-span-1">Kezelés</th>
              </tr>
            </thead>
            <tbody>
              {resourceColumnDynamicColsObj.map((col, index) => (
                <tr key={index} className={index % 2 === 0 ? 'bg-gray-100 grid grid-cols-4' : 'grid grid-cols-4'}>
                  <td className="border py-1 col-span-1">{col.name}</td>
                  <td className="border py-1 col-span-2">{col.dynPropertyName}</td>
                  <td className="border py-1 col-span-1">
                    <div className="flex justify-center">
                      <ButtonUI
                        text={dynamicColsVisibility[`col${index + 1}`] ? 'Elrejtés' : 'Visszaállítás'}
                        className=""
                        color="blue-gray"
                        variant="gradient"
                        size="sm"
                        onClick={() => handleChangeDynamicCol(index)}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="flex justify-center mt-5">
            <ButtonUI text="Bezárás" color="blue-gray" variant="gradient" onClick={handleClose} />
          </div>
        </div>
      </div>
    </DialogUI>
  );
};
