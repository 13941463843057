import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import { huHU } from '@mui/x-data-grid/locales';
import { isEqual } from 'lodash';
import useSaveView from '../../../hooks/useSaveView';
import useView from '../../../hooks/useView';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { getDataGridStyles } from './dataGridStyles';
import { DataGridIdentifiers } from './DataGridIdentifiers';
import useUsers from '../../../context/UsersContext';

const DataGridUI = ({ datagridIdentifier, ...props }) => {
  const isPremium = process.env.REACT_APP_ENABLE_PREMIUM_DATAGRID;

  const { getUser, user } = useUsers();
  const [prevState, setPrevState] = useState(null);
  const [currentState, setCurrentState] = useState(null);
  const apiRef = useGridApiRef();
  const saveView = useSaveView(user);
  const dataGrid = useView('dataGrid', user);

  const handleEvent = (_params, _event, _details) => {
    if (Object.keys(_params).length > 0) {
      const currentState = apiRef.current.exportState();
      setCurrentState(currentState);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (apiRef.current) {
      apiRef.current.subscribeEvent('aggregationModelChange', handleEvent);
      apiRef.current.subscribeEvent('columnVisibilityModelChange', handleEvent);
      apiRef.current.subscribeEvent('filterModelChange', handleEvent);
      apiRef.current.subscribeEvent('paginationModelChange', handleEvent);
      apiRef.current.subscribeEvent('rowGroupingModelChange', handleEvent);
      apiRef.current.subscribeEvent('sortModelChange', handleEvent);
    }
  }, [apiRef.current]);

  useEffect(() => {
    if (datagridIdentifier && !isEqual(prevState, currentState)) {
      saveView('dataGrid', { [datagridIdentifier]: currentState });
    }
  }, [prevState, currentState]);

  useEffect(() => {
    if (dataGrid?.[datagridIdentifier] && apiRef && user) {
      apiRef.current.restoreState(dataGrid?.[datagridIdentifier]);
      setPrevState(dataGrid?.[datagridIdentifier]);
    }
  }, [apiRef, user, datagridIdentifier]);

  if (!datagridIdentifier) {
    console.error('datagridIdentifier is required but was not provided.');
    return null; // Prevent the component from rendering if the identifier is not provided
  }

  return (
    <>
      {isPremium === '1' ? (
        <DataGridPremium
          pagination
          rowHeight={45}
          sx={getDataGridStyles(props)}
          slotProps={{
            columnsPanel: {
              sx: {
                '& .MuiDataGrid-panelFooter button:first-child': {
                  display: 'none',
                },
              },
            },
          }}
          localeText={huHU.components.MuiDataGrid.defaultProps.localeText}
          apiRef={apiRef}
          disableSelectionOnClick
          {...props}
        />
      ) : (
        <DataGrid
          rowHeight={45}
          sx={getDataGridStyles(props)}
          slotProps={{
            columnsPanel: {
              sx: {
                '& .MuiDataGrid-panelFooter button:first-child': {
                  display: 'none',
                },
              },
            },
          }}
          localeText={huHU.components.MuiDataGrid.defaultProps.localeText}
          apiRef={apiRef}
          disableSelectionOnClick
          {...props}
        />
      )}
    </>
  );
};

DataGridUI.propTypes = {
  datagridIdentifier: PropTypes.oneOf(Object.values(DataGridIdentifiers)).isRequired,
};

export default DataGridUI;
