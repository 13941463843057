import { useEffect, useState } from 'react';
import { GridRowModes, GridToolbarContainer, GridRowEditStopReasons } from '@mui/x-data-grid';
import { Field, Form, Formik } from 'formik';

import { NotificationType } from '../../config';
import addNotification from '../../utils/addNotification';
import { dataGridColumns } from '../../utils/helper';
import Yup from '../../utils/yup';
import projectService from '../../service/project.service';

import {
  DataGridUI,
  DataGridIdentifiers,
  ButtonUI,
  FormErrorUI,
  InputUI,
  ActionButtonUI,
  DatePickerUI,
} from '../Interface';
import dayjs from 'dayjs';

function EditToolbar(props) {
  const { setRows, setRowModesModel, rows, rowModesModel, selectedValues, setCurrentRow, disabled } = props;

  const handleClick = () => {
    const newRow = {
      id: Date.now(),
      name: '',
      startDate: dayjs(new Date()),
      endDate: dayjs(new Date()),
      isNew: true,
    };

    setRows((oldRows) => [newRow, ...oldRows]);
    setCurrentRow(newRow);

    setRowModesModel((oldModel) => ({
      ...oldModel,
      [newRow.id]: { mode: GridRowModes.Edit, fieldToFocus: 'id' },
    }));
  };

  const isNewRow = rows.some(
    (row) => row.isNew || Object.values(rowModesModel).some((row) => row.mode === GridRowModes.Edit)
  );

  return (
    <GridToolbarContainer>
      <ButtonUI
        className="bg-success mb-1"
        text="Új sor hozzáadása"
        disabled={isNewRow || !selectedValues.projectId || disabled}
        onClick={handleClick}
      />
    </GridToolbarContainer>
  );
}

const ProjectHoliday = ({ holiday, selectedValues, setRefetchTable, disabled, user, canEdit }) => {
  const { holidayList, setHolidayList } = holiday;
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [currentRow, setCurrentRow] = useState({});

  useEffect(() => {
    setRows(holidayList);
  }, [holidayList]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    startDate: Yup.string().required(),
    endDate: Yup.string().required(),
  });

  const handleAddRow = (updatedRow) => {
    const insertObject = {
      ...updatedRow,
      createdBy: user?.userId,
    };
    projectService.addHolidayToList(insertObject, selectedValues.projectId).then((data) => {
      addNotification({
        content: 'Sikeres hozzáadás!',
        type: NotificationType.SUCCESS,
      });
      setRefetchTable(true);
      setHolidayList((prev) => {
        return [...prev, data];
      });
    });
  };

  const handleUpdateRow = (updatedRow, id) => {
    const updData = {
      ...updatedRow,
      updatedBy: user?.userId,
    };
    projectService.updateHoliday(updData, id).then(() => {
      addNotification({
        content: 'Sikeres mentés!',
        type: NotificationType.SUCCESS,
      });
      setRefetchTable(true);
    });
  };

  const handleDeleteRow = (id) => {
    const deleteData = {
      deletedBy: user.userId,
    };
    projectService.deleteHolidayFromList(deleteData, id).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
      setHolidayList((prevOption) => prevOption.filter((option) => option.id !== id));
    });
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    const rowToEdit = rows.find((row) => row.id === id);
    setCurrentRow(rowToEdit);
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
    handleDeleteRow(id);
  };

  const handleCancelClick = (id) => () => {
    const editedRow = rows.find((row) => row.id === id);

    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    } else {
      setRows((prevRows) => prevRows.map((row) => (row.id === id ? { ...editedRow, isNew: false } : row)));
    }
    setCurrentRow(null);
  };

  const processRowUpdate = async (newRow) => {
    await validationSchema.validate(newRow, { abortEarly: false });
    const updatedRow = { ...newRow, isNew: newRow.isNew ?? false };
    const updatedRows = rows.map((row) => (row.id === newRow.id ? updatedRow : row));

    updatedRows.sort((a, b) => a.id - b.id);

    setRows(updatedRows);

    if (updatedRow.isNew) {
      handleAddRow(updatedRow);
    } else {
      handleUpdateRow(updatedRow, newRow.id);
    }
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = (setFieldValue, validateField, errors) => {
    return dataGridColumns([
      {
        field: 'id',
        headerName: 'Azon.',
        flex: 0.5,
        sortable: false,
        valueGetter: (params) => (params?.row.isNew ? '' : params.row.id),
      },
      {
        field: 'name',
        headerName: 'Rövid név',
        flex: 1,
        editable: !canEdit ? false : true,
        renderEditCell: (params) => (
          <Field
            name="name"
            as={InputUI}
            helperText={<FormErrorUI message={errors.name} />}
            variant="standard"
            onChange={(e) => {
              setFieldValue('name', e.target.value ?? '').then(() => {
                validateField('name');
              });
              params.api.setEditCellValue({
                id: params.id,
                field: params.field,
                value: e.target.value,
              });
            }}
          />
        ),
      },
      {
        field: 'startDate',
        headerName: 'Kezdő dátum',
        flex: 1,
        editable: !canEdit ? false : true,
        sortable: false,
        renderCell: (params) => {
          return dayjs(params.row.startDate).format('YYYY.MM.DD.');
        },
        renderEditCell: (params) => (
          <Field
            name="startDate"
            component={DatePickerUI}
            helperText={<FormErrorUI message={errors.startDate} />}
            variant="standard"
            value={dayjs(params.row.startDate)}
            onChange={(date) => {
              setFieldValue('startDate', date ?? '').then(() => {
                validateField('startDate');
              });
              params.api.setEditCellValue({
                id: params.id,
                field: params.field,
                value: date,
              });
            }}
          />
        ),
      },
      {
        field: 'endDate',
        headerName: 'Befejező dátum',
        flex: 1,
        editable: !canEdit ? false : true,
        sortable: false,
        renderCell: (params) => {
          return dayjs(params.row.endDate).format('YYYY.MM.DD.');
        },
        renderEditCell: (params) => (
          <Field
            name="endDate"
            component={DatePickerUI}
            helperText={<FormErrorUI message={errors.endDate} />}
            variant="standard"
            value={dayjs(params.row.endDate)}
            onChange={(date) => {
              setFieldValue('endDate', date ?? '').then(() => {
                validateField('endDate');
              });
              params.api.setEditCellValue({
                id: params.id,
                field: params.field,
                value: date,
              });
            }}
          />
        ),
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Szerk.',
        flex: 0.5,
        cellClassName: 'actions',
        getActions: ({ id }) => [
          <ActionButtonUI
            id={id}
            rowModesModel={rowModesModel}
            handleSaveClick={handleSaveClick}
            handleCancelClick={handleCancelClick}
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
            disabled={disabled}
          />,
        ],
      },
    ]);
  };

  return (
    <div className="overflow-x-auto">
      <div className="align-middle">
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
          <Formik
            initialValues={currentRow}
            validationSchema={validationSchema}
            onSubmit={processRowUpdate}
            validateOnMount
            enableReinitialize
          >
            {({ errors, setFieldValue, validateField }) => (
              <Form className="w-full">
                <DataGridUI
                  datagridIdentifier={DataGridIdentifiers.ProjectHoliday}
                  sx={{ height: 400 }}
                  columns={columns(setFieldValue, validateField, errors)}
                  rows={rows}
                  getRowId={(row) => row.id}
                  rowModesModel={rowModesModel}
                  onRowModesModelChange={handleRowModesModelChange}
                  onRowEditStop={handleRowEditStop}
                  processRowUpdate={processRowUpdate}
                  slots={{
                    toolbar: EditToolbar,
                  }}
                  slotProps={{
                    toolbar: {
                      setRows,
                      setRowModesModel,
                      rows,
                      rowModesModel,
                      user,
                      selectedValues,
                      setCurrentRow,
                      disabled,
                    },
                  }}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ProjectHoliday;
